<!--
* @description:
* @fileName pageEdit.vue
* @author hvv
* @date 2022/01/18 09:36:48
!-->
<template>
  <el-dialog
    v-model="dialogFormVisible"
    :title="title"
    width="500px"
    @close="close"
  >
    <el-form ref="formRef" label-width="80px" :model="form" :rules="rules">
      <el-form-item label="邮箱" prop="email">
        <el-input v-model="form.email" />
      </el-form-item>
      <el-form-item label="密码" prop="password">
        <el-input v-model="form.password" show-password placeholder='密码必须包含大小写和数字且要大于8位数'/>
      </el-form-item>
      <el-form-item label="手机号" prop="phone">
        <el-input v-model="form.phone" />
      </el-form-item>
    </el-form>
    <template #footer>
      <el-button @click="close">取 消</el-button>
      <el-button type="primary" @click="save">确 定</el-button>
    </template>
  </el-dialog>
</template>

<script>
  import {
    defineComponent,
    getCurrentInstance,
    onMounted,
    reactive,
    toRefs,
  } from 'vue'
  import { addSynergyAccount } from '@/api/exhibitionManage'
  import { useRoute } from 'vue-router'

  export default defineComponent({
    name: 'pageEdit',
    emits: ['fetch-data'],
    setup(props, { emit }) {
      const { proxy } = getCurrentInstance()

      const route = useRoute()
      const state = reactive({
        formRef: null,
        treeRef: null,
        checkMenu: [],
        eventList: [],
        form: {},
        rules: {
          phone: [{ required: true, trigger: 'blur', message: '请输入手机号' }],
          email: [{ required: true, trigger: 'blur', message: '请输入邮箱' }],
          password: [
            { required: true, trigger: 'blur', message: '请输入密码' },
          ],
        },
        title: '',
        dialogFormVisible: false,
        dialogVisible: false,
        dialogImageUrl: '',
        list: [],
      })

      const showEdit = async (row) => {
        state.form.meetingCode = route.query.meetingCode
        state.title = '添加'
        state.dialogFormVisible = true
      }
      const close = () => {
        state['formRef'].resetFields()
        state.form = {}
        state.dialogFormVisible = false
      }

      const save = () => {
        state['formRef'].validate(async (valid) => {
          if (valid) {
            try {
              await addSynergyAccount(state.form)
              proxy.$baseMessage(
                `操作成功`,
                'success',
                'vab-hey-message-success'
              )
              emit('fetch-data')
              close()
            } catch (e) {
              proxy.$baseMessage(`操作失败`, 'error', 'vab-hey-message-error')
            }
          }
        })
      }

      return {
        ...toRefs(state),
        showEdit,
        close,
        save,
      }
    },
  })
</script>

<style lang="scss" scoped>
  .vab-tree-border {
    height: 200px;
    padding: $base-padding;
    overflow-y: auto;
    border: 1px solid #dcdfe6;
    border-radius: $base-border-radius;
  }
</style>

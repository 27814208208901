<!--
 * @Author: Haoxiwei 1078734933@qq.com
 * @Date: 2023-04-27 11:17:12
 * @LastEditors: Haoxiwei 1078734933@qq.com
 * @LastEditTime: 2025-02-19 13:16:52
 * @FilePath: /wemeet-admin-new/src/views/exhibitionManage/components/editRelatedMeet.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <el-dialog v-model="dialogFormVisible" :title="title" width="500px" @close="close">
    <el-form ref="formRef" label-width="100px" :model="form" :rules="rules">
      <el-form-item label="论坛名称" prop="title">
        <el-input v-model="form.title"></el-input>
      </el-form-item>

      <el-form-item label="闸机供应商" prop="gateProvider">
        <el-select v-model="form.gateProvider">
          <template v-for="(item, index) in providerList" :key="index">
            <el-option :label="item.label" :value="item.value"></el-option>
          </template>
        </el-select>
      </el-form-item>

      <el-form-item label="闸机配置项" prop="gateProviderConfig">
        <el-input v-model="form.gateProviderConfig" placeHolder='配置项请输入JSON格式'></el-input>
      </el-form-item>

      <el-form-item label="论坛封面图" prop="coverUrl" label-width="100px">
        <el-upload class="upload-demo" action="#" list-type="picture-card" :limit="1" multiple :file-list="logoList" :http-request="upCover" :on-error="handleError" :on-exceed="handleExceed" :on-preview="handlePreview" :on-remove="handleRemove" accept=".png,.jpeg,.jpg">
          <el-icon class="el-icon--upload">
            <upload-filled />
          </el-icon>
          <div class="el-upload__text">
            <em style="font-size:12px;">尺寸要求: 5:4,大小不超过5MB</em>
          </div>
        </el-upload>
      </el-form-item>

      <el-form-item label="论坛标签" prop="typeCodes" v-if="wcs.tenantKey == 'J80A84ZBBA40PW1'">
        <el-select v-model="form.typeCodes" multiple>
          <template v-for="(item, index) in typeCodeLists" :key="index">
            <el-option :label="item.name" :value="item.code"></el-option>
          </template>
        </el-select>
      </el-form-item>

      <el-form-item label="议题方向" prop="agendaType" v-if="wcs.tenantKey == 'J80A84ZBBA40PW1'">
        <el-select v-model="form.agendaType">
          <template v-for="(item, index) in directionList" :key="index">
            <el-option :label="item.label" :value="item.label"></el-option>
          </template>
        </el-select>
      </el-form-item>

      <el-form-item label="议题方向" prop="agendaType" v-if="wcs.tenantKey == 'WeMeet' || wcs.tenantKey == 'GDC' ">
        <el-select v-model="form.agendaType">
          <template v-for="(item, index) in directionListByGdc" :key="index">
            <el-option :label="item.label" :value="item.label"></el-option>
          </template>
        </el-select>
      </el-form-item>



    </el-form>
    <template #footer>
      <el-button @click="close">取 消</el-button>
      <el-button type="primary" @click="save">确 定</el-button>
    </template>

    <el-dialog v-model="dialogVisible" append-to-body title="查看大图">
      <div>
        <el-image :src="dialogImageUrl" />
      </div>
    </el-dialog>
  </el-dialog>
</template>

<script>
import {
  defineComponent,
  getCurrentInstance,
  onMounted,
  reactive,
  toRefs,
} from 'vue'
import {
  getMeetingHostByPhone,
  updateRelatedMeetDetail,
  doRelatedMeet,
  queryRelatedMeetingType,
} from '@/api/exhibitionManage'

import { upLoadImg } from '@/api/active'
export default defineComponent({
  name: 'editRelated',
  emits: ['fetch-data'],
  props: {
    exCode: {
      type: String,
      default: '',
    },
  },
  setup(props, { emit }) {
    const { proxy } = getCurrentInstance()

    const state = reactive({
      form: {
        title: '',
        coverUrl: '',
        gateProvider: 'WEMEET',
        gateProviderConfig: '',
        agendaType: '',
      },
      size: 'large',
      rules: {
        title: [{ required: true, trigger: 'blur', message: '请输入论坛名称' }],
        coverUrl: [
          { required: true, trigger: 'change', message: '请选择封面图片' },
        ],
        // agendaType: [
        //   { required: true, trigger: 'change', message: '请选择议题方向' },
        // ],
        // typeCodes: [
        //   { required: true, trigger: 'change', message: '请选择论坛标签' },
        // ],
      },
      providerList: [
        {
          label: '澳龙',
          value: 'INFO_SALONS',
        },
        {
          label: '租达人',
          value: 'ZDR',
        },
        {
          label: 'WeMeet',
          value: 'WEMEET',
        },
      ],
      directionList: [
        {
          label: '稳步扩大制度型开放',
        },
        {
          label: '培育外贸新动能',
        },
        {
          label: '投资合作新机遇',
        },
        {
          label: '优化区域开放布局',
        },
        {
          label: '服务企业高质量“走出去”',
        },
        {
          label: '科技创新与新质生产力',
        },
        {
          label: '绿色低碳与可持续发展',
        },
        {
          label: '新型消费的趋势与前沿',
        },
      ],
      directionListByGdc: [
        {
          label: '讲坛',
        },
        {
          label: '工作坊',
        },
        {
          label: 'Demo Day',
        },
        {
          label: 'GDC Talk',
        },
      ],
      typeCodeLists: [],
      logoList: [],
      title: '编辑论坛',
      dialogFormVisible: false,
      dialogVisible: false,
      dialogImageUrl: '',
      list: [],
      hostInfo: null,
      formRef: null,
      wcs: {},
    })

    const showEdit = async (row) => {
      const data = JSON.parse(localStorage.getItem('customInfo'))
      state.wcs = data
      console.log( state.wcs)
      queryTypeLists()
      state.form = row
      state.form.title = row.meetingName || row.title
      if (!!row.coverUrl) {
        state.logoList.push({ name: 'logo', url: row.coverUrl })
      }

      state.form.typeCodes =
        state.form.meetingTypes && state.form.meetingTypes.map((v) => v.code)
      console.log(state.form, '=====>>>>')
      // 默认WeMeet
      state.form.gateProvider = !!state.form.gateProvider
        ? state.form.gateProvider
        : 'WEMEET'
      state.dialogFormVisible = true
    }
    const close = () => {
      state.form = {}
      state.logoList = []
      state.dialogFormVisible = false
    }

    const queryTypeLists = async () => {
      const { data } = await queryRelatedMeetingType({
        meetingCode: props.exCode,
      })
      console.log(data)
      state.typeCodeLists = data
    }

    const searchHostInfo = async () => {
      if (!!state.form.phone) {
        try {
          let phoneParams = { phone: state.form.phone }
          const { data: hostInfo } = await getMeetingHostByPhone(phoneParams)
          state.hostInfo = hostInfo
        } catch (e) {
          state.hostInfo = null
        }
      } else {
        proxy.$baseMessage(`手机号不能为空`, 'error', 'vab-hey-message-error')
      }
    }

    const handleJSON = (str) => {
      try {
        const toObj = JSON.parse(str) // json字符串转对象
        if (toObj && typeof toObj === 'object') {
          return true
        }
      } catch (e) {
        console.log(e)
      }
      return false
    }

    const save = async () => {
      state['formRef'].validate(async (valid) => {
        if (valid) {
          try {
            if (
              !!state.form.gateProviderConfig &&
              !handleJSON(state.form.gateProviderConfig)
            ) {
              proxy.$baseMessage(
                `JSON格式化错误，请确认输入格式是否正确`,
                'error',
                'vab-hey-message-error'
              )
              return false
            }

            if (state.form.status) {
              state.form.exMeetingCode = props.exCode
              const { data, msg } = await updateRelatedMeetDetail(state.form)
              proxy.$baseMessage(msg, 'success', 'vab-hey-message-success')
            } else {
              const { data, msg } = await doRelatedMeet(state.form)
              proxy.$baseMessage(msg, 'success', 'vab-hey-message-success')
            }
            close()
            emit('fetch-data')
          } catch (e) {
            proxy.$baseMessage(
              `系统错误，请重试`,
              'error',
              'vab-hey-message-error'
            )
          }
        }
      })
    }

    /**
     * @description: 上传图片相关接口
     */
    // 上传cover
    const upCover = async (e) => {
      let upData = new FormData()
      upData.append('file', e.file)
      const { data } = await upLoadImg(upData)
      state.form.coverUrl = data
    }

    const handleError = (err, file) => {
      proxy.$baseMessage(
        `文件[${file.raw.name}]上传失败,文件大小为${_.round(
          file.raw.size / 1024,
          0
        )}KB`,
        'error',
        'vab-hey-message-error'
      )
    }

    const handleExceed = (files) => {
      proxy.$baseMessage(
        `当前限制选择 ${proxy.limit} 个文件，本次选择了
             ${files.length}
             个文件`,
        'error',
        'vab-hey-message-error'
      )
    }
    const handleRemove = (file, fileList) => {
      state.form.shopLogo = ''
    }

    const handlePreview = (file) => {
      state.dialogImageUrl = file.url
      state.dialogVisible = true
    }

    return {
      ...toRefs(state),
      showEdit,
      close,
      searchHostInfo,
      save,
      upCover,
      handleError,
      handleExceed,
      handleRemove,
      handlePreview,
    }
  },
})
</script>

<style lang="scss" scoped>
.vab-tree-border {
  height: 200px;
  padding: $base-padding;
  overflow-y: auto;
  border: 1px solid #dcdfe6;
  border-radius: $base-border-radius;
}
.el-upload__text {
  line-height: 20px;
}
.userInfoBox {
  display: flex;
  justify-content: left;
  background: #67c23a;
  padding: 10px;
  box-sizing: border-box;
  .detailInfo {
    margin-left: 20px;
    color: #fff;
    font-size: 16px;
  }
}
</style>
